import React from 'react';
import { Link } from 'gatsby';

import MainLayout from '@hoc/layout';
import Seo from '@components/seo/seo';

import * as s from './404.module.scss';
import * as s_wrapper from '@assets/styles/wrapper.module.scss';
import * as s_btns from '@assets/styles/btns.module.scss';

const Home = () => {

  return (
    <MainLayout>

      <Seo
        title='Nie znaleziono tej strony'
        description='Nie znaleziono tej strony'
      />

      <section className={`${s.not_found_section} ${s_wrapper.apply} ${s_wrapper.apply_height}`}>

        <h1>Nie znaleziono tej strony</h1>

        <Link to='/' className={`${s_btns.apply} ${s.btn}`}>
          STRONA GŁÓWNA
        </Link>

      </section>

    </MainLayout>
  );

}

export default Home;
